.winner-results {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    text-transform: uppercase;
    font-size: 1.7em;
    text-align: center;

    .name {
      font-size: 3.5em;
      font-weight: bold;
    }

    .congrats-message {
      font-size: 1.7em;
      margin-top: 1em;
    }
  }
}
