.App {
  /* text-align: center; */
  position: relative;
}

body {
  background-color: #fff;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-display: optional;
}

body {
  background-color: #fff;
}

/*form { background: #000; padding: 3px; position: fixed; bottom: 0; width: 100%; }
form input { border: 0; padding: 10px; width: 90%; margin-right: .5%; }
form button { width: 9%; background: rgb(130, 224, 255); border: none; padding: 10px; }*/
#messages {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#messages li {
  padding: 5px 10px;
}

#messages li:nth-child(odd) {
  background: #eee;
}

#messages {
  margin-bottom: 40px;
}

.answer {
  position: absolute;
  /*top: 2;*/
  height: 20px;
  bottom: 6px;
  right: 0;
  width: 0;
  min-width: 0;
  transition: min-width 0.5s ease-in-out;
}

.answer-1 {
  background-color: #1f77b4;
}

.answer-2 {
  background-color: #80ced6;
}

.answer-3 {
  background-color: #ff8519;
}

.answer-4 {
  background-color: #bcbd22;
}

.answer-5 {
  background-color: #2ca02c;
}

.answer-6 {
  background-color: #d62728;
}

.answer-7 {
  background-color: #9467bd;
}

.answer-8 {
  background-color: #8c564b;
}

.answer-9 {
  background-color: #e377c2;
}

.answer-10 {
  background-color: #7f7f7f;
}

.answer-11 {
  background-color: #17becf;
}

.nav-caption {
  color: #aaa;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 8px;
}

.sidebar-content-list {
  padding: 0px;
}

.slide {
  position: absolute;
  left: 0;
  right: 0;
  top: 48px;
  bottom: 0;
  /*border-bottom: 2px solid #263570;*/
  /* border-bottom: 2px solid rgb(247, 143, 30); */
}

/* .quiz {
  position: absolute;
  left: 0;
  right: 0;
  top: 48px;
  bottom: 0;
} */

.quiz {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  min-height: 100vh;
}

.quiz__question {
  height: 30%;
  padding: 16px;
  position: relative;
  text-align: center;
  color: white;
  vertical-align: middle;
  /* background-color: rgb(247, 143, 30); */
  /* background-color: #263570; */
  background-color: #00477e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 14px;
}

.quiz__question.quiz__question--word_cloud,
.quiz__question.quiz__question--image_quiz,
.quiz__question.quiz__question--form {
  height: 20%;
}

.quiz__answers {
  /* height: 70%; */
  padding: 12px;
  text-align: center;
}

.quiz__answer {
  display: table;
  width: 100%;
  margin-bottom: 6px;
  min-height: 44px;
  cursor: pointer;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.quiz__answer.active {
  /* border-color: rgb(247, 143, 30); */
}

.quiz__answer__label {
  display: table-cell;
  width: 36px;
  padding: 4px;
  font-weight: bold;
  background-color: #aaa;
  text-align: center;
  vertical-align: middle;
  color: white;
}

.correct-answer {
  color: #16ab39;
  font-weight: bold;
}

.client-question-number {
  color: #16ab39;
  font-size: 20px;
  position: absolute;
  top: 0px;
  right: 0px;
  font-weight: bold;
}

.quiz__answer.active .quiz__answer__label {
  background-color: #000;
}

.quiz__answer.active.correct .quiz__answer__label {
  background-color: #16ab39;
}

.quiz__answer.active.vote .quiz__answer__label {
  background-color: #16ab39;
}

.quiz__answer.active.incorrect .quiz__answer__label {
  background-color: #aaa;
}

.quiz__answer.correct:not(active) .quiz__answer__label {
  background-color: #f44336;
}

.quiz__answer__text {
  display: table-cell;
  vertical-align: middle;
  padding: 4px 8px;
  font-size: 14px;
  text-align: left;
  border: 1px solid #ddd;
  border-left: 0;
}

.quiz__answer.active .quiz__answer__text {
  border-color: #000;
}

.quiz__answer.active.correct .quiz__answer__text {
  border-color: #16ab39;
}

.quiz__answer.active.vote .quiz__answer__text {
  border-color: #16ab39;
}

.quiz__answer.active.incorrect .quiz__answer__text {
  text-decoration: line-through;
  color: #f44336;
}

.quiz__answer.correct:not(active) .quiz__answer__text {
  border-color: #f44336;
}

.quiz__answers__button {
  display: inline-block;
  margin: 8px auto;
  text-transform: uppercase;
  text-align: center;
  padding: 8px 16px;
  cursor: pointer;
  color: white;
  font-weight: 300;
  border-radius: 4px;
  /* background-color: #263570; */
  background-color: #00477e;
  /* background-color: rgb(247, 143, 30); */
}

.quiz__answers__text {
  display: inline-block;
  margin: 8px auto;
  text-transform: uppercase;
  text-align: center;
  padding: 8px 8px;
  font-weight: 300;
}

.quiz__answers__tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.quiz__answers__tag {
  margin: 2px;
  border-radius: 4px;
  border: 1px solid rgb(153, 195, 85);
  color: rgb(153, 195, 85);
  display: inline-block;
  padding: 4px 8px;
  flex: 1 0 auto;
  font-size: 13px;
}

.quiz__answers__tag.active {
  background-color: rgb(153, 195, 85);
  color: white;
}

.slide {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.slide__question {
  position: absolute;
  top: 0;
  left: 0;
  padding: 48px;
  text-align: center;
  color: white;
  vertical-align: middle;
  background-color: #263570;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 300;
}

.slide__multiple {
  bottom: 0;
  width: 50%;
  font-size: 3vw;
}

.slide__image {
  height: 17vh;
  right: 0;
  font-size: 2.5vw;
}

.slide__answers {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 24px;
  text-align: center;
}

.slide__multiple__answers {
  top: 0;
  padding-top: 96px;
  width: 50%;
}

.slide__image__answers {
  top: 17vh;
  width: 100%;
}

.slide__answer {
  display: table;
  width: 100%;
  margin-bottom: 8px;
  min-height: 64px;
  cursor: pointer;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.slide__answer.active {
  /* border-color: rgb(247, 143, 30); */
}

.slide__answer__label {
  display: table-cell;
  width: 64px;
  padding: 4px;
  background-color: #aaa;
  text-align: center;
  vertical-align: middle;
  color: white;
}

.slide__answer.correct .slide__answer__label {
  background-color: #16ab39;
}

.slide__answer__text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  padding: 8px 16px;
  font-weight: 300;
  text-align: left;
  border: 1px solid #ddd;
  border-left: 0;
}

.slide__answer.correct .slide__answer__text {
  border-color: #16ab39;
}

.slide__answers__button {
  display: inline-block;
  margin: 8px auto;
  text-transform: uppercase;
  text-align: center;
  padding: 8px 16px;
  cursor: pointer;
  color: white;
  font-weight: 300;
  border-radius: 4px;
  background-color: #263570;
  /* background-color: rgb(247, 143, 30); */
}

.slide__answers__text {
  display: inline-block;
  margin: 8px auto;
  text-transform: uppercase;
  text-align: center;
  padding: 8px 8px;
  font-weight: 300;
  font-size: 2.5vw;
}

.slide .answer {
  /*top: 2;*/
  height: 16px;
  bottom: 4px;
}

.brick_wall {
  position: static;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-position: center;
}

.brick_container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: opacity;
}

@keyframes cssAnimation {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.brick_container .brick {
  width: 100%;
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  will-change: opacity;
}

.broken {
  animation: cssAnimation 1s forwards;
}

.brick_container .ball {
  /* display: none; */
  /* width: 10 */
}

.brick_container .ball {
  position: absolute;
  width: 10vw;
  height: auto;
  z-index: 1;
  top: 50%;

  transform: translateY(100vh) scale(1.5);
  /* left: 10vw; */
  /* height: 200vw; */
}

.brick_container .ball-1:not(.visible) {
  transform: translateX(10vw) translateY(100vh) scale(1.5);
}

.brick_container .ball-2:not(.visible) {
  transform: translateX(6vw) translateY(100vh) scale(1.5);
}

.brick_container .ball-3:not(.visible) {
  transform: translateX(2vw) translateY(100vh) scale(1.5);
}

.brick_container .ball-4:not(.visible) {
  transform: translateX(-2vw) translateY(100vh) scale(1.5);
}

.brick_container .ball-5:not(.visible) {
  transform: translateX(-6vw) translateY(100vh) scale(1.5);
}

.brick_container .ball-6:not(.visible) {
  transform: translateX(-10vw) translateY(100vh) scale(1.5);
}

.brick_container .ball:not(.visible) {
  opacity: 0;
}

.brick_container .ball.visible {
  display: block;
  transition: transform 1s ease-in, opacity 0.1s;
  transform: translateY(-50%) scale(0.5);
  animation: cssAnimation 1s forwards;
}

.brick_client__background {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.brick_client__ball .ball {
  position: absolute;
  width: 32vw;
  height: auto;
  top: 60%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.brick_client__ball .ball.launched {
  transform: translateX(-50%) translateY(-120vh);
  transition: transform 1s;
}

.master-answer-count {
  display: block;
  margin: 8px auto;
  text-transform: uppercase;
  padding: 8px 8px;
  font-weight: 300;
}

.master-answer-label {
  border-radius: 50%;
  border: 3px solid transparent;
  width: 32px;
  height: 32px;
  line-height: 29px;
  text-align: center;
}

.master-answer-label.active {
  color: green;
  border-color: rgb(34, 139, 34);
}

.quiz__confetti {
  position: absolute !important;
  top: 64%;
  left: 50%;
  width: 250px;
  height: 60px;
}

.quiz__question__text {
  margin-top: 10px;
  font-weight: 300;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  /* fontWeight: 600, */
}

.pie-chart__title {
  padding-left: 40px;
  font-size: 80px;
  text-transform: uppercase;
  font-weight: light;
}

.pie-chart__title strong {
  font-weight: bold;
}

/** Vote Image Styles **/
.quiz__vote__image .quiz__vote__image--group__container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.quiz__vote__image .quiz__vote__image--container {
  margin: 5px;
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.quiz__vote__image .quiz__vote__image--text {
  margin-bottom: 0px;
  margin-bottom: 15px;
  font-weight: bold;
}

/** Multiple Texts Styles **/
.quiz__multiple__texts__answers {
  display: block;
}

.quiz__multiple__texts__answers .input {
  display: block;
  outline: none;
  margin: 20px auto;
  padding: 8px 10px;
  width: 70vw;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.46);
  border: 2px solid #ccc;
  font-size: 2vh;
}

.quiz__multiple__texts__answers .add__button {
  width: 6vw;
  height: 6vw;
  background: #eee;
  margin: auto;
  border-radius: 3vw;
  align-items: center;
  justify-content: center;
  display: flex;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.46);
}

/** Scale Styles **/
.rc-slider {
  height: 36px !important;
}

.rc-slider-rail {
  height: 36px !important;
  /* right: -20px !important; */
  /* width: 105% !important; */
  width: 110% !important;
  right: -5% !important;
  box-shadow: 0 2px 1px 0 #ddd inset !important;
  border-bottom: 1px solid #ddd !important;
}

.rc-slider-track {
  height: 36px !important;
  background-color: transparent !important;
}

.rc-slider-handle {
  z-index: 100 !important;
  height: 50px !important;
  width: 30px !important;
  background-color: #000 !important;
  margin-top: -10px !important;
  border-radius: 4px !important;
}
.rc-slider-dot {
  display: none !important;
}

.quiz__scale__multiple--slider,
.quiz__scale__single {
  padding: 10px 50px;
}

@media only screen and (max-width: 500px) {
  /* limit padding on mobile devices */
  .quiz__scale__multiple--slider,
  .quiz__scale__single {
    padding: 10px 20px;
  }
}

.quiz__scale__multiple--container {
  display: flex;
  margin: 30px;
  align-items: center;
}

.quiz__scale__multiple--icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.quiz__scale__multiple--text {
  width: 25vw;
  font-weight: bold;
  margin: 0px;
  text-align: left;
}

.quiz__scale__multiple--subtitle {
  color: #999;
}

/** Multiple Choice/Vote : Checkbox template Styles **/
input.checkbox-field ~ .checkmark {
  position: absolute;
  height: 20px;
  width: 20px;
  left: 0px;
  top: 10px;
  background: #ddd;
}

input.af-checkbox-field:hover ~ .checkmark {
  background-color: #9faab7;
}

input.checkbox-field:checked ~ .checkmark {
  background-color: #ed1c24;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

input.checkbox-field:checked ~ .checkmark:after {
  display: block;
}

.checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.quiz_multiple_choice_vote {
  text-align: left;
}

.quiz_multiple_choice_vote .textarea-field {
  width: 100%;
}

.quiz_multiple_choice_vote .textarea-field textarea {
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 8px;
}

.quiz_multiple_choice_vote .textarea-field textarea:focus {
  outline: none;
  border: 1px solid #777;
}

.other-answers-container {
  text-align: center;
  width: 80%;
  margin: 20px auto;
  box-shadow: 3px 2px 7px #999;
  border-radius: 10px;
  padding: 20px;
}

.other-answers-container > .other-answers {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  margin: auto;
  max-height: 30vh;
  overflow-y: auto;
}

.dnd--column__innerContainer {
  margin: 8px;
  border: 1px solid rgb(204, 204, 204);
  box-shadow: rgb(199, 199, 199) 0px 1px 1px 0px inset;
  background-color: white;
  border-radius: 10px;
}

/** Extra question styles **/
.extra__question--container {
  padding: 10px;
  margin: 10px;
}

.extra__question--label {
  flex: 1;
  text-align: center;
  padding: 10px;
}

.extra__question--answers {
  display: flex;
  width: 100%;
}

.result-viewer__card {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  padding: 16px;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  /* box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); */
}

.result-viewer__card.card--auto-hide {
  opacity: 0;
  transition: ease-in-out 0.5s opacity;
}

.result-viewer__card.card--auto-hide:hover {
  opacity: 1;
}
