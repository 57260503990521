.slave-container {
  display: flex;
  padding: 20px;
  justify-content: flex-start;

  .result--container {
    &__pie--chart {
      margin: 20px auto;
    }
  }

  .question-container {
    margin-right: 8%;
    margin-left: 8%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    &.question-container--type-word_cloud {
      margin: 0 auto;
    }

    &__question {
      margin-bottom: 40px;

      &--number {
        display: none;
      }

      &--title {
        color: white;
        font-size: 6.5vh;
        font-weight: bold;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;

        &.long {
          font-size: 5vh;
        }

        .fa.fa-chevron-right {
          display: none;
        }
      }

      &--subtitle {
        font-size: 3vh;
        color: #999;
      }
    }
  }

  &--multiple_choice {
    .answer-text {
      .number {
        display: table-cell;
        width: 90px;
        min-width: 90px;
        text-align: right;
        line-height: 1.1em;
        padding-right: 15px;
        padding-bottom: 0.6em;
      }

      .text {
        display: table-cell;
        line-height: 1.1em;
        padding-bottom: 0.5em;
      }
    }
  }

  &--modern {
    background-color: white;

    .question-container {
      border-radius: 20px;

      &__question {
        background-color: white;
        padding: 20px 40px;
        border-radius: 20px;
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
          0 3px 5px -1px rgba(0, 0, 0, 0.2);

        &--number {
          display: block;
          font-size: 2vh;
          font-weight: bold;
          border: 1px solid #000;
          max-width: max-content;
          padding: 10px 20px;
          border-radius: 20px;
        }

        &--title {
          color: black;
        }
      }

      .answer-text {
        color: black;
      }
    }

    .topBorder {
      position: absolute;
      height: 10%;
      top: 0px;
      left: 0px;
      right: 0px;
      background-color: black;
    }

    &.slave-container {
      &--multiple_choice {
        border-collapse: separate;
        border-spacing: 10px;

        .answer-text {
          &.correct-answer {
            .text,
            .number {
              font-weight: bold !important;
            }
          }
        }
      }
    }
  }

  .question__percentage {
    display: table-cell;
    width: 160px;
    line-height: 1.1em;
    padding-bottom: 0.5em;
    padding-left: 32px;
  }

  &--stand {
    background-color: white;

    .question-container--type-multiple_choice .question__percentage {
      display: none;
    }

    .question-container {
      &__question {
        &--title {
          display: flex;
          color: black;

          .fa.fa-chevron-right {
            display: block;
          }
        }
      }
    }
  }

  .answer-input {
    &__answers {
      font-size: 3vh;
      width: 85vw;
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      max-height: 65vh;
      overflow: auto;
    }

    &__answer {
      width: calc(50% - 0.5em);
      display: inline;
      padding: 0.25em 0.5em;
      background: rgba(0, 0, 0, 0.25);
      margin-bottom: 0.5em;
      border-radius: 0.25em;
      color: #f0f0f0;
      line-height: 1.25;

      .count {
        padding: 0.1em 0.5em;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        display: none;
      }
    }
  }
}
