.slave-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: #333; */
  background-color: #00477e;
  /* TODO : customize */
}

.question-number-container {
  position: absolute;
  top: -7%;
  left: -7%;
}

.question-number {
  color: #8dc63f;
  font-size: 50px;
  font-family: "Arial";
  position: absolute;
  text-align: left;
  top: 10px;
  /* right: 0px; */
  left: 32px;
  /* align left to handle double-digit numbers */
  font-weight: bold;
}

.answer-text {
  /* font-family: "Montserrat"; */
  font-size: 5vh;
  color: white;
  list-style: none;
}
.answer-text>.number {
  text-align: right;
  padding-right: 2vw;
}

.answer-text>.number, .answer-text>.text {
  display: table-cell;
}

.correct-answer {
  color: #8dc63f;
  /* font-family: "Arial"; */
}

.answer-text.wrong-answer {
  opacity: 0.35;
  transition: 1s;
}

.question-chevron {
  color: #8dc63f;
  margin-left: 15px;
  margin-right: 15px;
}

.waiting-results-text {
  margin-top: 30px;
  font-family: "Arial";
  font-size: 45px;
  color: #8dc63f;
}

.question-text.question-text--small {
  font-size: 4.5vh;
  margin-bottom: 2vh;
}

.quiz-title {
  font-size: 7vh;
  font-family: "Arial";
}

.slave-container--stand {
  height: 91vh;
  align-items: flex-start;
  background-color: white;
}

.slave-container--stand .question-text .fa.fa-chevron-right {
  font-size: inherit;
  margin-right: 1vh;
  vertical-align: top;
}

.slave-container--stand.slave-container--results {
  align-items: center;
}

.slave-container--title,
.slave-container--form {
  /* background-color: #333; */
  background-color: #00477e;
  /* TODO : customize */
}

.slave-container .responses-count {
  border-radius: 10px;
  font-family: "Arial";
  font-size: 3vh;
  font-weight: bold;
  margin-bottom: 24px;
  border: 2px solid black;
  padding: 6px 10px;
}

.slave-container--stand .answer-text {
  color: #5f5f5f;
}

.slave-container--stand .answer-text.correct-answer {
  color: #00b050;
}

.slave-container--stand .answer-text span.correct {
  color: #00b050;
}

.slave-container--stand .answer-text span.wrong {
  color: red;
}

.slave-container--stand .question-container {
  align-items: flex-start;
}

.slave-container--stand .question-text {
  text-align: left;
  color: black;
  font-family: "Arial";
  font-weight: bold;
  margin-bottom: 55px;
}

.slave-container--word_cloud .question-text {
  font-size: 4.5vh;
}

.slave-container--word_cloud,
.slave-container--word_cloud .question-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.slave-container--word_cloud .question-container {
  margin: 0;
  padding: 8px;
}

.slave-container--title .question-text,
.slave-container--form .question-text {
  font-size: 11vh;
  color: white;
}

.slave-container--mobile .question-text {
  font-size: 7.5vw;
  padding-top: 10vh;
  padding-bottom: 5vh;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}

.slave-container--mobile .question-container {
  margin-bottom: 10vh;
}

.slave-pin {
  position: fixed;
  top: 8px;
  right: 12px;
  font-size: 1.5vw;
  color: white;
}

.slave-pin code {
  color: #8dc63f;
  font-size: 1.5vw;
}

.slave-container--word_cloud:not(.slave-container--show-question) .question-container__question,
.slave-container--word_cloud .slave-pin {
  display: none;
}

.slave-container--word_cloud .quiz-title {
  display: none;
}

.c-histogram .c-histogram__line h3 {
  margin-bottom: 0;
}

.c-leaderboard {
  margin-left: 10vw;
  background-color: #ff2b44;
  width: 400px;
  color: white;
  padding: 16px;
}

.c-leaderboard h3 {
  text-align: center;
  margin-bottom: 5vh;
}

.c-leaderboard ol {
  font-size: 1.5em;
}

.slave-leaderboard {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.slave-leaderboard td,
.slave-leaderboard th {
  border: 1px solid #ddd;
  padding: 10px 30px;
  font-size: 3.5vh;
  font-weight: normal;
}

.slave-leaderboard tr:nth-child(even) {
  background-color: #f2f2f2;
}

.slave-leaderboard tr:hover {
  background-color: #ddd;
}

.slave-leaderboard th {
  padding: 30;
  font-size: 4.5vh;
  text-align: left;
  background-color: #4caf50;
  color: white;
  font-weight: bold;
}

.slave__scale__single--average {
  text-align: center;
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
}

.slave__scale__single--slider__container {
  box-shadow: 0 2px 1px 0 #ddd inset;
  border-bottom: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px 20px;
  display: flex;
  margin: auto;
  background-color: white;
}

.slave__scale__single--slider__average {
  height: 50px;
  width: 10px;
  position: absolute;
  top: -8px;
  background-color: black;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.slave__scale__single--slider__tick {
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slave__scale__single--slider__tick div.active {
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 4px;
}

.slave__scale__single--progressbars__container {
  display: flex;
  padding: 10px 20px;
  margin-top: 20px;
}

.slave__scale__single--progressbars__progressbar {
  position: relative;
  width: 10px;
  border-radius: 4px;
  margin: auto;
  height: 200px;
  background: #ddd;
}

.slave__scale__single--progressbars__progressbar__percent {
  position: absolute;
  background: linear-gradient(#fbe14c, #ed6c2c);
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-radius: 4px;
}

.slave__scale__single--progressbars__progressbar__text {
  font-weight: bold;
  margin-top: 10px;
}
