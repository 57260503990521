.PieChart {
  &__legend {
    &__rectangle {
      width: 30px;
      margin-right: 10px;
    }

    &__percent {
    }
  }
}
