.client-given-answers {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.01);
  padding: 1em;

  .table {
    &__index {
      display: none;
      width: 1.5em;
      vertical-align: top;
      // font-size: 1.125em;

      .masteranswer-label {
        font-size: 1.5em;
      }
    }

    &__value {
      padding-left: 0;
      padding-right: 0;
    }

    &__text,
    &__count {
      font-size: 1.15em;
    }

    &__count {
      background: rgba(0, 0, 0, 0.05);
      line-height: 1;
      padding: 4px 8px;
      border-radius: 4px;
    }
  }
  .answer {
    height: 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}
